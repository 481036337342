@import 'utils';

.withHoverEffect {
  --mate-logo-fill-color: #{getColor($c-fill-brand)};
  --mate-logo-text-color: #{getColor($c-fill-brand-bold)};

  :global(#mate-logo-square-fill),
  :global(#mate-logo-square-text) {
    transition: fill $duration $easing;
  }

  &:hover {
    --mate-logo-fill-color: #{getColor($c-fill-brand-bold)};
    --mate-logo-text-color: #{getColor($c-icon-bold)};
  }
}
