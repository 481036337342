@import "utils";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 11;
  background-color: $c-white;
  display: flex;
  align-items: center;
  justify-content: center;
}
