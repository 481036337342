@import "utils";

.logoIcon {
  --mate-logo-fill-color: #{getColor($c-fill-brand-bold)};
  --mate-logo-text-color: #{getColor($c-icon-bold)};
  --mate-logo-title-color: #{getColor($c-text-default)};

  display: block;
  width: 147px;
  height: 48px;

  :global(#mate-logo-full-fill) {
    fill: var(--mate-logo-fill-color);
  }

  :global(#mate-logo-full-text) {
    fill: var(--mate-logo-text-color);
  }

  :global(#mate-logo-full-title) {
    fill: var(--mate-logo-title-color);
  }
}
