@import "utils";

@keyframes placeholder {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.inputPlaceholder {
  @extend %input;
  @extend %input-disabled;
  opacity: 1;
  padding: rem-calc(16 24);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    @include covers-parent;
    content: '';
    background: linear-gradient(
        -60deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 70%
    );
      animation: placeholder 2s $easing infinite;
  }
}
