@import "utils";

.small {
  width: 16px;
  height: 16px;
}

.medium {
  width: 36px;
  height: 36px;
}

.large {
  width: 60px;
  height: 60px;
}

.vacancyApplyingIcon {
  width: 100%;
  stroke: getColor($c-bg-neutral-tertiary);
}

.IconEditWithUnderline {
  padding-left: 4px;
}
