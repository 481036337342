@import "utils";

.logoIcon {
  --mate-logo-fill-color: #{getColor($c-fill-brand-bold)};
  --mate-logo-text-color: #{getColor($c-icon-bold)};
  --mate-logo-title-color: #{getColor($c-text-default)};

  display: block;
  @include square(44);

  :global(#mate-logo-square-fill) {
    fill: var(--mate-logo-fill-color);
  }

  :global(#mate-logo-square-text) {
    fill: var(--mate-logo-text-color);
  }

  :global(#mate-logo-square-title) {
    fill: var(--mate-logo-title-color);
  }
}
