@import "utils";

%button-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

%button-base {
  @extend %button-reset;
  @extend %text-truncate;
  @include text-platform-h4;

  color: var($c-button-text);
  text-align: center;

  background-color: var($c-button-background);
  border: 1px solid var($c-button-border, $c-transparent);
  transition-timing-function: $easing;
  transition-duration: $duration;
  transition-property: background-color, color, border-color, box-shadow;

  &:disabled,
  &:global(.__disabled) {
    @extend %button-disabled;
  }
}

.loader {
  background-color: var($c-button-background);
}

.spinner {
  border-color: var($c-button-text);
  border-left-color: var($c-button-background);
}

.button {
  @extend %button-base;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:global(.wide) {
    width: 100%;
  }

  &:global(.disabled),
  &:disabled,
  &:global(.__disabled) {
    @extend %button-disabled;
  }
}

.small {
  padding: rem-calc(8 12);
  max-height: rem-calc(32);
  border-radius: rem-calc(6);
}

.large {
  padding: rem-calc(16 24);
  max-height: rem-calc(48);
  border-radius: rem-calc(10);
}

.primary {
  #{$c-button-background}: getColor($c-fill-inverse);
  #{$c-button-text}: getColor($c-text-inverse);
  #{$c-button-border}: getColor($c-border-inverse);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-inverse-hovered);
    #{$c-button-border}: getColor($c-fill-inverse-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-background}: getColor($c-fill-inverse-focused);
    #{$c-button-border}: getColor($c-border-inverse);
    box-shadow: 0 0 0 1px getColor($c-border-inverse);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-inverse-pressed);
    #{$c-button-border}: getColor($c-fill-inverse-pressed);
    outline: none;
  }
}


.secondary {
  #{$c-button-background}: getColor($c-fill-secondary);
  #{$c-button-text}: getColor($c-text-default);
  #{$c-button-border}: getColor($c-border);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-secondary-hovered);
    #{$c-button-border}: getColor($c-border-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-background}: getColor($c-fill-secondary-hovered);
    #{$c-button-border}: getColor($c-border-hovered);
    box-shadow: 0 0 0 1px getColor($c-border-hovered);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-secondary-pressed);
    #{$c-button-border}: getColor($c-border-pressed);
    outline: none;
  }
}

.neutral {
  #{$c-button-background}: getColor($c-bg-neutral);
  #{$c-button-text}: getColor($c-text-default);
  #{$c-button-border}: getColor($c-border);

  @include cursorHover {
    #{$c-button-border}: getColor($c-border-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-border}: getColor($c-border-hovered);
    box-shadow: 0 0 0 1px getColor($c-border-hovered);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-border}: getColor($c-border-pressed);
    outline: none;
  }
}


.brandPrimary {
  #{$c-button-text}: getColor($c-text-bold);
  #{$c-button-background}: getColor($c-fill-brand-bold);
  #{$c-button-border}: getColor($c-fill-brand-bold);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-brand-bold-hovered);
    #{$c-button-border}: getColor($c-fill-brand-bold-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-background}: getColor($c-fill-brand-bold-hovered);
    #{$c-button-border}: getColor($c-border-brand);
    box-shadow: 0 0 0 1px getColor($c-border-brand);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-brand-bold-pressed);
    #{$c-button-border}: getColor($c-fill-brand-bold-pressed);
  }
}

.brandSecondary {
  #{$c-button-text}: getColor($c-text-brand);
  #{$c-button-background}: getColor($c-fill-brand);
  #{$c-button-border}: getColor($c-border-brand);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-brand-hovered);
    #{$c-button-border}: getColor($c-border-brand);
  }

  &:focus-visible,
  &:global(.__focus) {
    box-shadow: 0 0 0 1px getColor($c-border-brand);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-brand-pressed);
    #{$c-button-border}: getColor($c-fill-brand-pressed);
  }
}

.green {
  #{$c-button-background}: getColor($c-fill-success-bold);
  #{$c-button-text}: getColor($c-text-bold);
  #{$c-button-border}: getColor($c-fill-success-bold);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-success-bold-hovered);
    #{$c-button-border}: getColor($c-fill-success-bold-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-border}: getColor($c-border-success);
    box-shadow: 0 0 0 1px getColor($c-border-success);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-success-bold-pressed);
    #{$c-button-border}: getColor($c-fill-success-bold-pressed);
  }
}

.transparentDark {
  #{$c-button-background}: transparent;
  #{$c-button-text}: $c-white;

  @include cursorHover {
    #{$c-button-background}: $c-gray-70;
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-background}: $c-gray-70;
    box-shadow: 0 0 0 2px $c-gray-40;
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: $c-gray-80;
    #{$c-button-text}: $c-gray-20;
  }

  &:disabled,
  &:global(.disabled) {
    #{$c-button-text}: $c-gray-50;
  }
}

.transparentLight {
  #{$c-button-background}: transparent;
  #{$c-button-border}: transparent;
  #{$c-button-text}: getColor($c-text-default);

  @include cursorHover {
    #{$c-button-background}: getColor($c-fill-secondary-hovered);
    #{$c-button-border}: getColor($c-border-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-background}: getColor($c-fill-secondary);
    #{$c-button-border}: getColor($c-border-hovered);
    box-shadow: 0 0 0 1px getColor($c-border-hovered);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-background}: getColor($c-fill-secondary-pressed);
    #{$c-button-border}: getColor($c-border-pressed);
  }
}

.transparentContent {
  display: flex;
  opacity: 0;
}

.withIcon.small {
  padding-top: rem-calc(4);
  padding-bottom: rem-calc(4);
}

.withIcon.large {
  padding-top: rem-calc(12);
  padding-bottom: rem-calc(12);
}

.onlyIcon {
  --border-radius: #{rem-calc(6)};
}

.onlyIcon.small {
  padding: rem-calc(4);
}

.onlyIcon.large {
  padding: rem-calc(12);
}

.fullWidth {
  width: 100%;
}

.grayStyling {
  #{$c-button-text}: getColor($c-text-tertiary);

  @include cursorHover {
    #{$c-button-text}: getColor($c-text-default);
    #{$c-button-background}: getColor($c-fill-secondary-hovered);
    #{$c-button-border}: getColor($c-border-hovered);
  }

  &:focus-visible,
  &:global(.__focus) {
    #{$c-button-text}: getColor($c-text-default);
    #{$c-button-background}: getColor($c-fill-secondary);
    #{$c-button-border}: getColor($c-border);
    box-shadow: 0 0 0 1px getColor($c-border);
    outline: none;
  }

  &:active,
  &:global(.__pressed) {
    #{$c-button-text}: getColor($c-text-default);
    #{$c-button-background}: getColor($c-fill-secondary-pressed);
    #{$c-button-border}: getColor($c-border-pressed);
  }
}
