@import "utils";

.logoIcon {
  --mate-logo-fill-color: #{getColor($c-fill-brand-bold)};
  --mate-logo-text-color: #{getColor($c-icon-bold)};

  display: block;
  @include square(32);

  :global(#mate-logo-circle-fill) {
    fill: var(--mate-logo-fill-color);
  }

  :global(#mate-logo-circle-text) {
    fill: var(--mate-logo-text-color);
  }
}
