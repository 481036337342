@import "utils";

.logoIcon {
  --knowely-logo-text-color: #{getColor($c-icon-bold)};
  --knowely-logo-title-color: #{getColor($c-text-default)};

  display: block;
  width: 147px;
  height: 48px;

  :global(#knowely-logo-full-text) {
    fill: var(--knowely-logo-text-color);
  }

  :global(#knowely-logo-full-title) {
    fill: var(--knowely-logo-title-color);
  }
}
