@use "sass:meta" as ---80xfmqohtu6;@mixin elipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin elipsis-vertical($lines: 5) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin wrap-text($width: 100%) {
  display: inline-block;
  max-width: $width;
  white-space: normal;
  word-wrap: break-word;
}

@mixin covers-parent {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

@mixin visually-hidden {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;

  clip: rect(0 0 0 0) !important;
}

@mixin placeholderAnimation(
  $light-mode-color: getCoreColor('white'),
  $dark-mode-color: getCoreColor('neutral-11')
) {
  @keyframes placeholder {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(100%);
    }
  }

  &::before {
    @include covers-parent;

    @include dark-mode-styles {
      background: linear-gradient(
        -60deg,
        rgba($dark-mode-color, 0) 30%,
        rgba($dark-mode-color, 1) 50%,
        rgba($dark-mode-color, 0) 70%
      );
    }

    @include light-mode-styles {
      background: linear-gradient(
        -60deg,
        rgba($light-mode-color, 0) 30%,
        rgba($light-mode-color, 1) 50%,
        rgba($light-mode-color, 0) 70%
    );
    }

    content: '';
    animation: placeholder 2s $easing infinite;
  }
}

@mixin smoothScroll {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@mixin withTabIndicator {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    border-radius: 2px 2px 0 0;
    transition: background-color $duration $easing;
    @content;
  }
}

@mixin getDelays($total, $multiplier) {
  @for $i from 0 to $total {
    &:nth-child(#{$i + 1}) {
      transition-delay: #{$i * $multiplier}s;
    }
  }
}

@mixin mediumLarge {
  @include breakpoint($extraMediumDown) {
    @content;
  }
}

@mixin redCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-width: rem-calc(17);
  height: rem-calc(18);
  padding-left: rem-calc(1);

  content: attr(data-circle-count);

  background: getColor($c-fill-brand-bold);
  color: getColor($c-text-bold);

  border-radius: 9px;
  @include text-platform-h5;
}

@mixin square($size) {
  width: rem-calc($size);
  height: rem-calc($size);
  aspect-ratio: 1;
}

@mixin circle($size) {
  @include square($size);

  border-radius: 50%;
}

@mixin hideOnDesktop {
  display: none;

  @include breakpoint(small down) {
    @content;
  }
}

@mixin hideOnMobile {
  @include breakpoint(small down) {
    display: none;
  }
}

@mixin hideOnExtraMediumUp {
  display: none;

  @include breakpoint($extraMediumDown) {
    @content;
  }
}

@mixin divideBottomLine {
  width: 100%;
  position: relative;

  &::after {
    content: '';
    width: 100vw;
    height: 1px;
    background: getColor($c-border);
    position: absolute;
    bottom: rem-calc(-8);
    left: rem-calc(-32);
    top: auto;
    right: auto;
  }
}

@mixin fullHeight {
  height: 100vh;
  max-height: stretch;

  @include breakpoint(medium down) {
    height: 100dvh;
  }
}

@mixin flexGrowContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@mixin cursorHover {
  @media (hover: hover) {
    &:hover,
    &:global(.__hover) {
      @content;
    }
  }
}

// Set opacity values in decimal format (0.8, 0.5, 0.2, etc.).
@mixin skeletonLoading($bgOpacity: 0, $lineOpacity: 0) {
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  background-image: linear-gradient(
      100deg,
      transparentize($c-gray-10, $bgOpacity) 15%,
      transparentize($c-white, $lineOpacity) 25%,
      transparentize($c-gray-10, $bgOpacity) 35%
  );
  background-size: 200% 100%;
  animation: $duration_skeleton_loading shine linear infinite;
}

@mixin paddingVertical($padding) {
  padding-top: rem-calc($padding);
  padding-bottom: rem-calc($padding);
}

@mixin paddingHorizontal($padding) {
  padding-left: rem-calc($padding);
  padding-right: rem-calc($padding);
}

@mixin marginVertical($margin) {
  margin-top: rem-calc($margin);
  margin-bottom: rem-calc($margin);
}

@mixin marginHorizontal($margin) {
  margin-left: rem-calc($margin);
  margin-right: rem-calc($margin);
}

@mixin fixed-size($width, $height) {
  width: $width;
  min-width: $width;
  max-width: $width;
  height: $height;
  min-height: $height;
  max-height: $height;
}

@mixin absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin noTouchSelect {
  -webkit-user-select: none; /* Safari */
  -webkit-touch-callout: none; /* Safari */
  -webkit-tap-highlight-color: transparent; /* Prevents highlight on tap */
  user-select: none;
  touch-action: none; /* Prevents default touch behavior */
}

;@include ---80xfmqohtu6.load-css("sass-embedded-legacy-load-done:75122");