@import "utils";

// landing typography
.withAccentText {
  @include accent-styles {
    color: $c-red;
  }
}

.withGrayAccentText {
  @include accent-styles {
    color: $c-gray-90;
  }
}

.landingH1 {
  @include text-landing-h1;
}

.landingH2 {
  @include text-landing-h2;
}

.landingH3 {
  @include text-landing-h3;
}

.landingH4 {
  @include text-landing-h4;
}

.landingH5 {
  @include text-landing-h5;
}

.landingH6 {
  @include text-landing-h6;
}

.landingTextMain {
  @include text-landing-main;
}

.paragraph {
  white-space: pre-line;
}

// platform typography
.platformH1 {
  @include text-platform-h1;
}

.platformH2 {
  @include text-platform-h2;
}

.platformH3 {
  @include text-platform-h3;
}

.platformH4 {
  @include text-platform-h4;
}

.platformH5 {
  @include text-platform-h5;
}

.platformTextMain {
  @include text-platform-main;
}

.platformTextSecondary {
  @include text-platform-secondary;
}

.platformTextSmall {
  @include text-platform-small;
}

// redesign typography

.newLandingH2 {
  @include new-landing-h2;
}

.newLandingH3 {
  @include new-landing-h3;
}

.newLandingH4 {
  @include new-landing-h4;
}

.newLandingH5 {
  @include new-landing-h5;
}

.newLandingH6 {
  @include new-landing-h6;
}

.landingSectionHeading {
  @include text-landing-section-heading;
}

.newLandingTextMain {
  @include new-landing-text-main;
}

.newLandingTextSecondary {
  @include new-landing-text-secondary;
}

// New landings typography | Headings
.landingHeadingXSmall {
  @include landing-heading-xsmall;
}

// New landings typography | Paragraphs
.landingParagraphMedium {
  @include landing-paragraph-medium;
}
