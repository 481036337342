@import "utils";

.radioContainer {
  display: flex;
  cursor: pointer;
  @include text-platform-main;

  &:hover .radioDecoration,
  &:global(._hover) .radioDecoration {
    @extend %input-hover;
  }

  &.radioContainerDisabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.radioInputPrimary {
    --radio-background: #{getColor($c-fill-inverse)};
  }

  &.radioInputRed {
    --radio-background: #{getColor($c-icon-brand)};
  }

  &.radioInputGreen {
    --radio-background: #{getColor($c-icon-success)};
  }

  &.radioInputPurple {
    --radio-background: #{getColor($c-icon-accent-purple)};
  }

  &.radioInputGray {
    --radio-background: #{getColor($c-switch-fill)};
  }
}

.radioDecoration {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem-calc(16);
  width: rem-calc(16);
  background-color: $c-transparent;
  border-radius: 50%;
  border: 1px solid getColor($c-switch-fill);
  margin-right: rem-calc(12);
  margin-top: rem-calc(4);

  transition-timing-function: $easing;
  transition-duration: $duration;
  transition-property: color, border-color, opacity, background-color;
}

.radioIcon {
  height: rem-calc(8);
  width: rem-calc(8);
  border-radius: 50%;
  background-color: getColor($c-icon-inverse);
  transition: opacity $duration $easing;
  opacity: 0;
}

.radioInput {
  @include visually-hidden;

  &:disabled + .radioDecoration {
    background-color: getColor($c-fill-disabled);
  }

  &:checked + .radioDecoration {
    background-color: var(--radio-background, getColor($c-fill-inverse));
    border-color: var(--radio-background, getColor($c-border-inverse));

    .radioIcon {
      opacity: 1;
    }
  }

  &:focus + .radioDecoration,
  :global(._focus) & + .radioDecoration {
    @extend %input-focus;
  }
}

.label {
  color: getColor($c-text-default);
}
