@use "sass:meta" as ---80xfmqohtu6;// media
$global-font-size: 16px;

$extraMediumDown: 899px down;
$extraMediumUp: 900px up;

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$grid-container: 90rem;
$grid-margin-gutters: (
  small: 16px,
  medium: 24px,
  large: 24px,
);
$grid-container-padding: (
  small: 32px,
  medium: 48px,
  large: 80px,
);
$breakpoint-classes: (small medium large xlarge xxlarge);

$header-height: --header-height;
$topic-banner-height: --topic-banner-height;

$footer-height: --footer-height;
$landing-header-height: --landing-header-height;
$scrollbar-width: --scrollbar-width;
$sidebar-width: --sidebar-width;
$cta-button-height: --cta-button-height;
$cta-banner-height: --cta-banner-height;
$promo-banner-height: --promo-banner-height;
$platform-container-indent: --platform-container-indent;
$sessions-header-height: --session-header-height;
$tech-check-management-header-height: --tech-check-management-header-height;
$platform-header-height: --platform-header-height;
$chats-header-height: --chats-header-height;
$chats-mobile-header-height: --chats-mobile-header-height;
$topic-select-horizontal-padding: --topic-select-horizontal-padding;
$website-page-form-container-width: --website-page-form-container-width;
$website-page-modal-container-width: --website-page-modal-container-width;
$auto-messages-header-height: --auto-messages-header-height;
$vh: var(--vh, 1vh);
$chat-notifications-banner-height: --chat-notifications-banner-height;
$mobile-footer-height: --mobile-footer-height;
$responsive-modal-handle-height: --responsive-modal-handle-height;
$full-screen-modal-z-index: --full-screen-modal-z-index;

;@include ---80xfmqohtu6.load-css("sass-embedded-legacy-load-done:75118");