@import "utils";

.modalContainer {
  border-radius: rem-calc(32);
}

.maModal {
  outline: none;
  width: calc(100% - #{rem-calc(128)});
  background-color: getColor($c-bg-neutral);
  max-width: rem-calc(668);
  margin: rem-calc(64);
  overflow: hidden;

  pointer-events: visible;

  @include breakpoint(small down) {
    width:  calc(100% - #{rem-calc(32)});
    margin: rem-calc(16);
  }
}

.verticalTopPositioning {
  align-self: flex-start;
}

.verticalBottomPositioning {
  align-self: flex-end;
}

.stretch {
  max-width: 80vw;
}

.maModalLoading {
  @extend %covers-parent;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $c-transparent;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.maModalOverlay {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: rem-calc(-70);
  transform: translateY(rem-calc(70px));
  padding-bottom: rem-calc(70);
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: getColor($c-blur);
  padding-right: var(#{$scrollbar-width});

  &Disabled {
    pointer-events: none;
    background-color: transparent;
  }
}

.autoWidth {
  width: auto;
  @include breakpoint(small down) {
    width: 100%;
    margin: 0;
  }
}

.titleContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem-calc(16 24);
  color: getColor($c-text-default);

  @include breakpoint(small down) {
    padding: rem-calc(24);
  }

  .iconAndTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem-calc(16);

    @include icon-styles {
      flex-shrink: 0;
      width: rem-calc(40);
      height: rem-calc(40);
    }
  }
}

.defaultTitleContainer {
  padding-bottom: rem-calc(24);
  margin-bottom: rem-calc(24);

  &::after {
    content: '';

    height: 1px;
    padding: 0 rem-calc(32);

    background-color: getColor($c-border);

    position: absolute;
    bottom: 0;
    left: rem-calc(32);
    right: rem-calc(32);
  }
}

.content {
  --modal-content-padding: #{rem-calc(32)};

  position: relative;
  color: getColor($c-text-secondary);
  border-radius: 16px;
  background-color: getColor($c-bg-neutral);
  padding: 0 var(--modal-content-padding) var(--modal-content-padding);

  @include breakpoint(small down) {
    --modal-content-padding: #{rem-calc(24)};
  }
}

.scrollArea {
  @extend %smooth-scroll;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-height: var(--modal-max-height, calc(100vh - #{rem-calc(256)}));
}

.unbounded {
  overflow: unset;
  overflow-y: unset;
}

.paddingless {
  padding: 0;
}

.borderless {
  border-radius: 0;
}

.videoModal {
  max-width: 60vw;
}

.fullScreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  border-radius: 0;
  max-width: unset;
  min-height: 100dvh;
}

.fullScreenStretch {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fullScreenScrollArea {
  max-height: unset;
}

.closeButton {
  color: getColor($c-icon);
  cursor: pointer;
  place-self: flex-start;
  display: flex;
  align-self: end;
}

.closeButtonOnTop {
  position: absolute;
  top: 0;
  right: 0;
}
