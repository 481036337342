@import "utils";

.input {
  @extend %input;
  @extend %input-padding;

  &:hover,
  &:global(._hover) {
    @extend %input-hover;
  }

  @include disabled {
    @extend %input-disabled;
  }

  @include invalid {
    @extend %input-invalid;
  }

  &:focus,
  &:global(._focus) {
    @extend %input-focus;
  }

  @include input-placeholder-styles {
    @include text-platform-main;
    color: getColor($c-text-tertiary);
  }

    /* clears the ‘X’ from Internet Explorer */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.googleCitySelect {
  :global(.ma-select__placeholder){
    color: getColor($c-icon-tertiary) !important;
  }

  :global(.pac-target-input) {

    @include input-placeholder-styles {
      color: transparent !important;
    }
  }
}
