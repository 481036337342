@import "utils";

.select {
  @include text-platform-main;

  :global(.ma-select__control) {
    @extend %input-reset;
    @extend %input;
    @include elipsis();

    display: flex;
    height: max-content;

    box-shadow: none;
    cursor: pointer;

    &:global(.ma-select__control--is-disabled) {
      @extend %input-disabled;
    }
  }

  @include invalid {
    :global(.ma-select__control) {
      @extend %input-invalid;
    }
  }

  :global(.ma-select__value-container) {
    padding: 0;
  }

  :global(.ma-select__control--is-focused),
  :global(.ma-select__control--menu-is-open),
  &:global(._focus) :global(.ma-select__control) {
    @extend %input-focus;
  }

  :global(.ma-select__control--menu-is-open) {
    & :global(.ma-select__dropdown-indicator) {
      transform: rotate(180deg);
    }
  }

  :global(.ma-select__single-value) {
    @include elipsis();
    color: getColor($c-text-secondary);
  }

  :global(.ma-select__multi-value) {
    background-color: getColor($c-fill-secondary-hovered);
    border: 2px solid getColor($c-border-hovered);
    color: getColor($c-text-secondary);
    border-radius: 4px;

    pointer-events: none;

    &:hover {
      background-color: getColor($c-bg-inverse);
      border-color: getColor($c-border-inverse);
      color: getColor($c-text-inverse);
    }
  }

  :global(.ma-select__multi-value__label) {
    color: inherit;
  }

  :global(.ma-select__multi-value__remove) {
    pointer-events: auto;

    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }

  :global(.ma-select__indicator-separator) {
    display: none;
  }

  :global(.ma-select__dropdown-indicator) {
    color: getColor($c-icon-secondary);
    border-radius: rem-calc(4);
    transition: background-color $duration $easing;

    &:hover {
      color: getColor($c-icon-secondary);
      background-color: getColor($c-fill-secondary-hovered);
    }
  }

  :global(.ma-select__menu) {
    padding: rem-calc(8 0);
    margin-bottom: rem-calc(8);
    border-radius: rem-calc(4);
    z-index: 12;
    background-color: getColor($c-bg-neutral);
    color: getColor($c-text-secondary);

    box-shadow: none;
    border: 1px solid getColor($c-border);
  }

  :global(.ma-select__option) {
    color: getColor($c-text-secondary);
    cursor: pointer;
    transition: color $duration $easing, background-color $duration $easing;
    background-color: $c-transparent;
    padding: rem-calc(8 16);

    &:hover {
      background-color: getColor($c-fill-hovered);
    }

    &:global(.ma-select__option--is-selected) {
      color: currentColor;
      font-weight: $font-weight_extra-bold;
      background-color: getColor($c-fill-tertiary-hovered);

      &:active {
        background-color: getColor($c-fill-tertiary-pressed);
      }
    }

    &:global(.ma-select__option--is-focused) {
      color: currentColor;
      background-color: getColor($c-fill-hovered);

      &:active {
        background-color: getColor($c-fill-pressed);
      }
    }

    &:global(.ma-select__option--is-disabled) {
      cursor: not-allowed;
      background-color: $c-transparent;
      color: getColor($c-text-disabled);
    }
  }

  &.open-top {
    :global(.ma-select__menu) {
      transform: translateY(calc(-100% - 64px));
    }
  }
}

.menuWidthByContent {
  :global(.ma-select__menu) {
    width: min-content;
  }
}

.menuWidthMaxContent {
  :global(.ma-select__menu) {
    width: max-content;
  }
}

.menuWidthByControl {
  :global(.ma-select__menu) {
    width: 100%;
  }
}

.multiSelect {
  :global(.ma-select__control) {
    min-height: rem-calc(48);
    height: max-content;
  }
}

.regularMode {
  :global(.ma-select__input) {
    color: getColor($c-text-secondary);

    input {
      @include text-platform-secondary;
    }
  }

  :global(.ma-select__single-value) {
    padding-right: rem-calc(8);
  }

  :global(.ma-select__placeholder) {
    color: getColor($c-text-tertiary);
  }

  :global(.ma-select__control) {
    min-height: rem-calc(48);
    padding: 0 rem-calc(16);

    transition: border-color $duration $easing;
  }

  &:hover,
  &:global(._hover) {
    :global(.ma-select__control) {
      @extend %input-hover;
    }
  }
}

.borderlessMode {
  :global(.ma-select__single-value),
  :global(.ma-select__placeholder) {
    @include text-platform-h4;

    padding: 0;
    width: max-content;

    color: getColor($c-text-secondary);
  }

  :global(.ma-select__input) {
    input {
      @include text-platform-h4;
    }
    color: getColor($c-text-secondary);
  }

  :global(.ma-select__placeholder) {
    margin: rem-calc(0 8 0 3);
  }

  :global(.ma-select__control) {
    @include text-platform-h4;

    width: 100%;
    min-height: rem-calc(24);
    padding: 3px 4px;
    border-radius: rem-calc(6);

    border-color: transparent;

    transition: background-color $duration $easing;

    &:hover {
      border-color: getColor($c-border-hovered);
    }
  }

  :global(.ma-select__control--is-focused) {
    border-color: getColor($c-border-pressed);
  }

  :global(.ma-select__dropdown-indicator) {
    padding: 0;
    color: getColor($c-icon-secondary);
    border-radius: rem-calc(4);

    transition: background-color $duration $easing;

    &:hover {
      background-color: getColor($c-fill-secondary-hovered);
      color: getColor($c-icon-secondary);
    }
  }

  :global(.ma-select__value-container) {
    width: 100%;
    height: rem-calc(24);
    align-items: flex-start;
  }

  :global(.ma-select__value-container--is-multi) {
    height: rem-calc(28);
  }

  :global(.ma-select__multi-value__label) {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
