@import "utils";

.title {
  display: flex;
  align-items: center;
}

.buttons {
  display: grid;
  margin-bottom: 2px;
  grid-template-columns: repeat(2, fit-content(100%));
  column-gap: rem-calc(16);
  justify-content: center;

  @include breakpoint(small down) {
    grid-template-columns: 100%;
    row-gap: rem-calc(16);
  }

  & > button {
    min-width: rem-calc(160);
  }
}

.buttonsDefault {
  grid-template-columns: 100%;
}

.reverseButtons {
  grid-auto-flow: dense;

  :first-child {
    grid-column: 2;
  }

  @include breakpoint(small down) {
    :first-child {
      grid-row: 2;
      grid-column: 1;
    }
  }
}

.overlay {
  z-index: 100 !important; // should overlay all other elements

  @for $i from 1 through 5 {
    &.level-#{$i} {
      z-index: 100 + $i !important;
    }
  }
}
