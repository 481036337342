@import "utils";

.logoIcon {
  --knowely-logo-fill-color: #{getColor($c-fill-brand-bold)};
  --knowely-logo-text-color: #{getColor($c-icon-bold)};

  display: block;
  @include square(32);

  :global(#knowely-logo-square-fill) {
    fill: var(--knowely-logo-fill-color);
  }

  :global(#knowely-logo-square-text) {
    fill: var(--knowely-logo-text-color);
  }
}
