@import "utils";

.container {
  @extend %covers-parent;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
  background-color: getColor($c-bg-neutral);
  transition: opacity $duration $easing;
  z-index: 2;
}

.spinner {
  position: relative;

  font-size: rem-calc(10);
  text-indent: -9999em;

  border: 1.1em solid getColor($c-border-hovered);
  border-left-color: getColor($c-border-inverse);
  transform: translateZ(0);
  animation: spin 1.1s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}

.container:global(.color-transparent) {
  background-color: getColor($c-bg-transparent);
}

.spinner:global(.color-red) {
  border-left-color: getColor($c-border-danger-bold);
}

.spinner:global(.color-gray) {
  border-left-color: getColor($c-text-tertiary);
}

.spinner:global(.color-transparent) {
  border-color: getColor($c-border-transparent);
  border-left-color: getColor($c-border-info-bold);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.warning {
  background-color: getColor($c-bg-warning);

  .spinner {
    border-left-color: getColor($c-border-warning-bold);
  }
}

.info {
  background-color: getColor($c-bg-info);

  .spinner {
    border-left-color: getColor($c-border-info-bold);
  }
}
