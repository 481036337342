@import "utils";

.icon {
  pointer-events: none;
  flex-shrink: 0;
}

.iconLeft {
  margin-right: rem-calc(4);
  margin-left: -4px;
}

.iconRight {
  margin-left: rem-calc(4);
  margin-right: -4px;
}

.buttonText {
  @extend %text-truncate;
}
