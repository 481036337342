@import "utils";

@keyframes slashes {
  0% {
    width: 0;
  }
}

.wrapper {
  height: 100dvh;
  color: getColor($c-text-default);
  background-color: getColor($c-bg-neutral);
}

.logo {
  @include dark-mode-styles {
    color: getColor($c-icon-inverse);
  }
}

.title {
  width: rem-calc(78);
  display: flex;
  align-items: baseline;
}

.dots {
  width: rem-calc(20);
  white-space: nowrap;
  overflow: hidden;
  animation: slashes 3s steps(4) infinite;
}

.text {
  width: rem-calc(300);
  margin: 0 auto;
}

.loaderWrapper {
  position: relative;
  background-color: transparent;
}

.loaderSpinner {
  border-color: getColor($c-border);
  border-left-color: getColor($c-border-inverse);
}
